.GDCarousel_whiteArrows {
    border: none;
}
.GDCarousel_whiteArrows span {
    border-color: #f8f9fb !important;
}
.GDCarousel_whiteArrows:hover span {
    border-color: black !important;
}
.GDCarousel__whiteArrows:hover:enabled span {
    border-color: #f8f9fb !important;
}

.GDCarousel__arrows {
    position: relative;
    padding: 21px;
    border: none;
    overflow: hidden;
    outline: 0;
    font-size: 0;
    line-height: 0;
    background-color: transparent;
    border: none;
    width: 36px;
    height: 36px;
    cursor: pointer;
    align-self: center;
}
.GDCarousel__arrows span {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    border-style: solid;
    border-color: #1c1c1c;
    border-width: 3px 3px 0 0;
    padding: 5px;
    transition: 0.3s;
    font-size: 0;
}
.GDCarousel__arrows:hover {
    background-color: #f8e837;
}
.GDCarousel__arrows:hover span {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    border-style: solid;
    border-color: #1c1c1c;
    border-width: 3px 3px 0 0;
    padding: 5px;
    transition: 0.3s;
    font-size: 0;
}
.GDCarousel__arrows:hover:enabled {
    background-color: #f8e837;
}
.GDCarousel__arrows:hover:enabled span {
    border-color: #1c1c1c;
    margin: 0;
}
.GDCarousel__arrows:disabled {
    background-color: #ccc;
}
.GDCarousel__arrowLeft span {
    transform: translate(-50%, -50%) rotate(-135deg);
    margin-left: 2.45px;
}
.GDCarousel__arrowRight span {
    transform: translate(-50%, -50%) rotate(45deg);
    margin-left: -2.45px;
}
.GDCarousel--isRTL .GDCarousel__arrowLeft span {
    transform: translate(-50%, -50%) rotate(45deg);
    margin-left: -2.45px;
}
.GDCarousel--isRTL .GDCarousel__custom-arrowLeft span {
    transform: rotate(180deg);
}
.GDCarousel--isRTL .GDCarousel__arrowRight span {
    transform: translate(-50%, -50%) rotate(-135deg);
    margin-left: 2.45px;
}
.GDCarousel--isRTL .GDCarousel__custom-arrowRight span {
    transform: rotate(-180deg);
}
.GDCarousel--isRTL .GDCarousel__arrows:hover span {
    margin: 0;
}
.GDCarousel__arrow--disable {
    pointer-events: none;
}
